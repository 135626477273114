* {
    /* border: 1px solid red; */
}

body {
    margin: 0;
    background-color: #01161e;
}

@import "~fluid-player/src/css/fluidplayer.css";
